import { addDays, formatISO } from 'date-fns';
import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useMemo, useState } from 'react';
import { feedback } from 'react-feedbacker';
import { useForm } from 'react-hook-form';
import { useMutation } from 'urql';

import { useCurrencies } from '@/bits';
import {
  Card,
  CardCloseButton,
  CardOptions,
  DateTimeField,
  ErrorMessage,
  NakedForm,
  NumberField,
  SelectField,
  SelectOption,
  TextField,
  TextareaField,
  useModalContext,
} from '@/components';
import { SubmitButton } from '@/components/form/SubmitButton';
import { useTranslate } from '@/contexts';
import { ClaimType, GameProvider, RewardTypeEnum } from '@/globalTypes';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import { rewardGameProviders } from '@/utils';
import { assert } from '@/utils/error';
import { GameSearch } from '../../components/GameSearch';
import { PlayersSearch } from '../../components/PlayersSearch';
import {
  CreateFreeSpinsMutation,
  CreateFreeSpinsMutationVariables,
} from './__generated__/CreateFreeSpinsMutation';
import {
  CreateMoneyDropMutation,
  CreateMoneyDropMutationVariables,
} from './__generated__/CreateMoneyDropMutation';
import { RewardCreateFormStaticQuery } from './__generated__/RewardCreateFormStaticQuery';

const query = graphql`
  query RewardCreateFormStaticQuery {
    sanityRewardCreateForm {
      title {
        ...LocaleString
      }
      rewardCreated {
        ...LocaleString
      }
      rewardType {
        ...LocaleString
      }
      rewardType {
        ...LocaleString
      }
      value {
        ...LocaleString
      }
      currency {
        ...LocaleString
      }
      numberOfSpins {
        ...LocaleString
      }
      paylines {
        ...LocaleString
      }
      game {
        ...LocaleString
      }
      gameProviders {
        ...LocaleString
      }
      nameOfReward {
        ...LocaleString
      }
      startDate {
        ...LocaleString
      }
      endDate {
        ...LocaleString
      }
      claimableInstant {
        ...LocaleString
      }
      players {
        ...LocaleString
      }
      notes {
        ...LocaleString
      }
    }
  }
`;

const createFreeSpinsMutation = gql`
  mutation CreateFreeSpinsMutation(
    $rewardName: String!
    $claimType: ClaimType!
    $startsAt: OffsetDateTime!
    $endsAt: OffsetDateTime
    $expiresIn: Duration
    $comment: String!
    $playerIds: [String!]!
    $spinsNumber: Int!
    $spinsValue: Long!
    $betLevel: Int!
    $currencyCode: ISOCurrencyCode!
    $gameProviderId: GameProvider!
    $gameMobileId: String
    $gameDesktopId: String
  ) {
    createFreeSpins(
      rewardName: $rewardName
      claimType: $claimType
      startsAt: $startsAt
      endsAt: $endsAt
      expiresIn: $expiresIn
      comment: $comment
      playerIds: $playerIds
      spinsNumber: $spinsNumber
      spinsValue: $spinsValue
      betLevel: $betLevel
      currencyCode: $currencyCode
      gameProviderId: $gameProviderId
      gameMobileId: $gameMobileId
      gameDesktopId: $gameDesktopId
    )
  }
`;

const createMoneyDropMutation = gql`
  mutation CreateMoneyDropMutation(
    $rewardName: String!
    $claimType: ClaimType!
    $startsAt: OffsetDateTime!
    $endsAt: OffsetDateTime
    $expiresIn: Duration
    $comment: String!
    $playerIds: [String!]!
    $amount: PositiveBigDecimal!
    $currencyCode: ISOCurrencyCode!
  ) {
    createMoneyDrop(
      rewardName: $rewardName
      claimType: $claimType
      startsAt: $startsAt
      endsAt: $endsAt
      expiresIn: $expiresIn
      comment: $comment
      playerIds: $playerIds
      amount: $amount
      currencyCode: $currencyCode
    )
  }
`;

type Rewards = 'FreeSpinsType' | 'MoneyDropType';
type RewardOption = SelectOption & { value: Rewards };
type FormValues = {
  rewardName: string;
  rewardType: RewardTypeEnum;
  claimType: ClaimType;
  startsAt: Date;
  endsAt?: Date | null;
  expiresIn?: Duration | null;
  comment: string;
  playerIds: string[];
  spinsNumber: number | null;
  spinsValue: number | null;
  betLevel: number;
  gameProvider: GameProvider;
  currencyCode: ISOCurrencyCode;
  gameDetails: {
    mobileId?: string | null;
    desktopId?: string | null;
  };
};

const useOptions = () => {
  return useMemo(() => {
    const rewardTypesOptions: RewardOption[] = [
      {
        label: 'FreeSpins',
        value: RewardTypeEnum.FreeSpinsType,
      },
      {
        label: 'MoneyDrop',
        value: RewardTypeEnum.MoneyDropType,
      },
    ];

    const claimOptions: SelectOption[] = [
      {
        label: 'Claimable',
        value: 'Manual',
      },
      {
        label: 'Instant',
        value: 'Instant',
      },
    ];

    return {
      rewardTypesOptions,
      claimOptions,
    };
  }, []);
};

const RewardCreateForm: FC = () => {
  const { t } = useTranslate();
  const staticData = useStaticQuery<RewardCreateFormStaticQuery>(query);
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const isMounted = useIsMounted();
  const { close } = useModalContext();

  const form = staticData.sanityRewardCreateForm;
  assert(form, 'missing form data');

  const { rewardTypesOptions, claimOptions } = useOptions();
  const { currencyOptions } = useCurrencies();

  const [createFreeSpinsState, createFreeSpins] = useMutation<
    CreateFreeSpinsMutation,
    CreateFreeSpinsMutationVariables
  >(createFreeSpinsMutation);

  const [createMoneyDropState, createMoneyDrop] = useMutation<
    CreateMoneyDropMutation,
    CreateMoneyDropMutationVariables
  >(createMoneyDropMutation);

  const defaultValues: FormValues = {
    rewardType: RewardTypeEnum.FreeSpinsType,
    claimType: ClaimType.Manual,
    rewardName: '',
    startsAt: new Date(),
    endsAt: addDays(new Date(), 14),
    expiresIn: undefined,
    comment: '',
    playerIds: [],
    spinsNumber: null,
    spinsValue: null,
    betLevel: 1,
    gameProvider: GameProvider.Relax,
    gameDetails: {
      mobileId: '',
      desktopId: '',
    },
    currencyCode: 'EUR' as ISOCurrencyCode,
  };

  const methods = useForm<FormValues>({
    defaultValues,
  });

  const rewardType = methods.watch('rewardType');
  const selectedGameProvider = methods.watch('gameProvider');

  const onSubmit = (values: FormValues) => {
    if (values.rewardType === 'FreeSpinsType') {
      const variables: CreateFreeSpinsMutationVariables = {
        rewardName: values.rewardName,
        claimType: values.claimType,
        startsAt: formatISO(values.startsAt),
        endsAt: values.endsAt ? formatISO(values.endsAt) : null,
        expiresIn: values.expiresIn,
        comment: values.comment,
        playerIds: values.playerIds,
        spinsNumber: Number(values.spinsNumber),
        spinsValue: Number(values.spinsValue),
        betLevel: Number(values.betLevel),
        currencyCode: values.currencyCode,
        gameProviderId: selectedGameProvider,
        gameMobileId: values.gameDetails.mobileId,
        gameDesktopId: values.gameDetails.desktopId,
      };

      return createFreeSpins(variables).then((res) => {
        if (res.error?.message && isMounted) {
          setErrorMessage(res.error.message);
        } else if (close) {
          feedback.success(t(form.rewardCreated));
          close();
        }
      });
    }

    const variables: CreateMoneyDropMutationVariables = {
      rewardName: values.rewardName,
      claimType: values.claimType,
      startsAt: formatISO(values.startsAt),
      endsAt: values.endsAt ? formatISO(values.endsAt) : null,
      expiresIn: values.expiresIn,
      comment: values.comment,
      playerIds: values.playerIds,
      amount: Number(values.spinsValue),
      currencyCode: values.currencyCode,
    };

    return createMoneyDrop(variables).then((res) => {
      if (res.error?.message && isMounted) {
        setErrorMessage(res.error.message);
      } else if (close) {
        feedback.success(t(form.rewardCreated));
        close();
      }
    });
  };

  if (!form) {
    return null;
  }

  return (
    <Card
      size="lg"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="flex p-6">
        <NakedForm className="w-full" onSubmit={onSubmit} methods={methods}>
          <div className="flex sm:flex-row flex-col space-x-6 pb-6">
            <div className="flex-1 space-y-4">
              <SelectField
                name="rewardType"
                id="RewardCreateForm__rewardType"
                title={t(form.rewardType)}
                required
                options={rewardTypesOptions}
              />
              <div className="grid sm:grid-cols-2 gap-2">
                <TextField
                  name="spinsValue"
                  required
                  id="RewardCreateForm__spinsValue"
                  title={t(form.value)}
                />
                <SelectField
                  name="currencyCode"
                  required
                  id="RewardCreateForm__currency"
                  title={t(form.currency)}
                  options={currencyOptions}
                />
              </div>
              {rewardType === 'FreeSpinsType' ? (
                <>
                  <div className="grid sm:grid-cols-2 gap-2">
                    <NumberField
                      name="spinsNumber"
                      required
                      id="RewardCreateForm__spinsNumber"
                      title={t(form.numberOfSpins)}
                      step="1"
                    />
                    <NumberField
                      name="betLevel"
                      required
                      id="RewardCreateForm__betLevel"
                      title={t(form.paylines)}
                      step="1"
                    />
                  </div>
                  <SelectField
                    name="gameProvider"
                    id="RewardCreateForm__gameProviders"
                    title={t(form.gameProviders)}
                    options={rewardGameProviders}
                  />
                  <GameSearch
                    name="gameDetails"
                    id="RewardCreateForm__gameDetails"
                    title={t(form.game)}
                    providerName={selectedGameProvider}
                  />
                </>
              ) : null}
            </div>
            <div className="flex-1 space-y-4">
              <TextField
                name="rewardName"
                id="RewardCreateForm__rewardName"
                title={t(form.nameOfReward)}
                required
              />
              <div className="grid sm:grid-cols-2 gap-2">
                <DateTimeField
                  title={t(form.startDate)}
                  name="startsAt"
                  id="analytics-block-settings__startDate"
                  required
                />
                <DateTimeField
                  title={t(form.endDate)}
                  name="endsAt"
                  id="analytics-block-settings__endDate"
                  required
                />
              </div>
              <SelectField
                name="claimType"
                id="RewardCreateForm__claimType"
                title={t(form.claimableInstant)}
                options={claimOptions}
                required
              />
              <PlayersSearch
                name="playerIds"
                id="RewardCreateForm__playerIds"
                title={t(form.players)}
              />
            </div>
            <div className="flex-1">
              <TextareaField
                name="comment"
                title={t(form.notes)}
                id="RewardCreateForm__comment"
              />
            </div>
          </div>

          <ErrorMessage message={errorMessage} />
          <div className="flex justify-end">
            <SubmitButton
              value="Create Reward"
              disabled={
                createFreeSpinsState.fetching || createMoneyDropState.fetching
              }
            />
          </div>
        </NakedForm>
      </div>
    </Card>
  );
};

export default RewardCreateForm;
